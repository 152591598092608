
$(document).ready(function() {

	if (typeof event_seen === 'undefined') {
		event_seen = 1;
	}
	
	if(event_seen) { console.log('event_seen = '+event_seen); }

	if(event_seen == 0) {
    	$('#modal_event').modal('show');
    }

    $('#modal_event .reserve').on('click', function() {
    	$('#modal_event').modal('hide');
    	$('#modal_galerie_reservation').modal('show');
    });

});