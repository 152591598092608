
$(document).ready(function() {

	$('.section .carte_entry.add_form select').click(function() {

		choix_select = $(this).val();
		form_object = $(this).closest('form');

		if(choix_select == 'title') {
			form_object.find('.entry_subtxt').addClass('hidden');
			form_object.find('.entry_price').addClass('hidden');
		}

		if(choix_select == 'carte_entry') {
			form_object.find('.entry_subtxt').removeClass('hidden');
			form_object.find('.entry_price').removeClass('hidden');
		}
	});

	/*$('.check_modified_inputs .textarea_tinymce').hover(function() {
		$(this).addClass('input_modified');
		$(this).parent().parent().find('.line_modified').val('1');
	});

	$('.check_modified_inputs select').click(function() {
		$(this).addClass('input_modified');
		$(this).parent().parent().find('.line_modified').val('1');
	});*/



});