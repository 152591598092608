
// Affichage de la map google map si la div existe
if (document.getElementById('carte_googlemaps')) { 

  function initialisation(){
    var optionsCarte = {
      zoom: 11,
      center: new google.maps.LatLng(46.370051, 6.326358),
      'scrollwheel': false,
      zoomControl: true,
      zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
      },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false
    }
    var maCarte = new google.maps.Map(document.getElementById("carte_googlemaps"), optionsCarte);

    var optionsMarqueur = {
          position: maCarte.getCenter(),
          map: maCarte
      };
      var marqueur = new google.maps.Marker(optionsMarqueur);
  }
  google.maps.event.addDomListener(window, 'load', initialisation);

};
// Fin affichage google maps