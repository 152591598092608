
$(document).ready(function() {

    $('.timeslots_filters_table .case').click(function() {
        clicked_case = $(this);
        timeslot = clicked_case.attr('data-timeslot');
        date = clicked_case.attr('data-date');
        active = clicked_case.attr('data-active');
        //console.log('timeslot : '+timeslot+', date : '+date+', active : '+active);

        var data = new Array();
        data.push({name: "timeslot", value: timeslot});
        data.push({name: "date", value: date});
        data.push({name: "active", value: active});

        $.ajax({
            url: 'php/ajax_timeslots_filters.php',
            type: 'post',
            data: $.param(data),
            success: function(resultat) { //console.log(resultat);
                
                // La case était inactive, il faut l'activer
                if(active == 0) {
                    clicked_case.addClass('active');
                    clicked_case.attr('data-active', 1);

                // La case était active, il faut la désactiver
                } else {
                    clicked_case.removeClass('active');
                    clicked_case.attr('data-active', 0);
                }
            }
        });
    });

    $('#horaires_filters .check_all').click(function() {
        month_container = $(this).parent().parent();
        month = month_container.attr('data-nbmonth');
        var data = new Array();
        data.push({name: "month", value: month});

        $.ajax({
            url: 'php/ajax_timeslots_filters_checkall.php',
            type: 'post',
            data: $.param(data),
            success: function(resultat) { //console.log(resultat);

                month_container.find('.case').addClass('active');
                month_container.find('.case').attr('data-active', 1);
            }
        });
    });

    $('#horaires_filters .uncheck_all').click(function() {
        month_container = $(this).parent().parent();
        month = month_container.attr('data-nbmonth');
        var data = new Array();
        data.push({name: "month", value: month});

        $.ajax({
            url: 'php/ajax_timeslots_filters_uncheckall.php',
            type: 'post',
            data: $.param(data),
            success: function(resultat) { //console.log(resultat);

                month_container.find('.case').removeClass('active');
                month_container.find('.case').attr('data-active', 0);
            }
        });
    });

});