/*
Script by : Clem
---------------------------------------
||              JS Menu              ||
---------------------------------------


*/


$(document).ready(function() {
	
	// GESTION DU CLIC SUR UN BOUTON DE LA NAV JS
	$('.js-date-explorer .nav').on('click', function(e) { // Au clic sur un élément
		/*e.preventDefault();*/

		if($(this).hasClass('previous_date')) { var direction = 'previous'; }
		if($(this).hasClass('next_date')) { var direction = 'next'; }

		var explorer_object = $(this).closest('.js-date-explorer');
		var clicked_group = explorer_object.attr('date-group');
		var current_id = explorer_object.attr('date-id');
		current_id = parseInt(current_id);

		if(direction == 'previous') { active_id = current_id + 1; }
		if(direction == 'next') { active_id = current_id - 1; }

		// Enlever tous les "active" du même groupe
		$i = 0;
		$('.js-date-explorer').each(function() {
			var group = $(this).attr('date-group');
			if(group == clicked_group) {
				$(this).removeClass('active');
				$i++;
			}
		});

		var last_id = $i - 1;

		if(debug_mode == true) { 
			console.log('clicked_group : '+clicked_group);
			console.log('current_id : '+current_id);
			console.log('direction : '+direction);
			console.log('active_id : '+active_id);
			console.log('number_of_block : '+$i);
			console.log('last_id : '+last_id);
		}

		// Repasser en active le bon block en fonction de la direction
		new_active_block = $(".js-date-explorer[date-id='"+active_id+"']");
		new_active_block.addClass('active');

		// Cacher la flèche de gauche ou de droite si on est au début ou à la fin des blocks
		new_active_block.find('.next_date').removeClass('hidden');
		new_active_block.find('.previous_date').removeClass('hidden');
		if(active_id == 0) {
			new_active_block.find('.next_date').addClass('hidden');
		}
		if(active_id == last_id) {
			new_active_block.find('.previous_date').addClass('hidden');
		}

	});

});