
$(document).ready(function() {

    $('.onoff_form_full button').click(function() {
        clicked_button = $(this);
        clicked_button_name = $(this).attr('name');
    });

	$(document).on('submit', '.onoff_form_full', function(e) {

        e.preventDefault(); // Empêcher la soumission du formulaire
        var $form_object = $(this); // L'objet jQuery du formulaire

        var data = $(this).serializeArray();
        data.push({name: "clicked_button", value: clicked_button_name});

        // Récuperation du bouton
        button_restaurant = $(this).find('.submit_onoff.restaurant');
        button_terrasse = $(this).find('.submit_onoff.terrasse');
        input_hidden_restaurant = $(this).find("input[name='restaurant_full']");
        input_hidden_terrasse = $(this).find("input[name='terrasse_full']");

        // Envoi de la requête HTTP en mode asynchrone
        $.ajax({
            url: $form_object.attr('action'), // Le nom du fichier indiqué dans le formulaire
            type: $form_object.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
            data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
            success: function(resultat) { // Je récupère la réponse du fichier PHP
                /*alert(resultat);*/
                if(resultat == 'add_restaurant') { button_restaurant.addClass('active'); input_hidden_restaurant.val('1'); }
                if(resultat == 'delete_restaurant') { button_restaurant.removeClass('active'); input_hidden_restaurant.val('0'); }
                if(resultat == 'add_terrasse') { button_terrasse.addClass('active'); input_hidden_terrasse.val('1'); }
                if(resultat == 'delete_terrasse') { button_terrasse.removeClass('active'); input_hidden_terrasse.val('0'); }
            }
        });
    });
	
});