

// Ouverture des dropdowns si option true
if(navbar_dropdown_on_over == true) {
$('.nav-item.dropdown').hover(
	function() {
		$(this).find('[data-toggle=dropdown]').dropdown('toggle');
	});
}

// Ajout d'une classe sur le body en cas d'ouverture du toggle
$('.navbar-toggler').click(function() {
	btn = $('.navbar-toggler');
	if($('.full_site').hasClass('navbar_opened')) {
		$('.full_site').removeClass('navbar_opened');
	}
	else {
		$('.full_site').addClass('navbar_opened');
	}
	if($('.navbar-collapse').hasClass('show')) {
		$('.navbar-collapse').css('transition', '0.5s');
		$('.navbar-collapse').css('transform', 'translateX(100%)');
	} else {
		$('.navbar-collapse').css('transition', '0.5s');
		$('.navbar-collapse').css('transform', 'translateX(0%)');
	}
	window.setTimeout(function(){ 
        btn.prop('disabled',true);
    },50);
	window.setTimeout(function(){ 
        btn.prop('disabled',false);
    },750);
});


