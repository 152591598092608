
$(document).ready(function() {

	$(".add_day").click(function() {

        if($(this).hasClass('fermeture')) {
            $(this).parent().find('.add_day').before('<input type="date" class="form-control" name="fermeture_exceptionnelle[]" value="">');
        }

        if($(this).hasClass('ouverture')) {
            $(this).parent().find('.add_day').before('<input type="date" class="form-control" name="ouverture_exceptionnelle[]" value="">');
        }
        
    });
	
});