
$('.nav-item.reservation').click(
	function() {
		/*$('.navbar').toggle();*/
		/*$('.navbar-collapse').css('transition', '0.5s');
		$('.navbar-collapse').css('transform', 'translateX(100%)');
		$('.full_site').removeClass('navbar_opened');*/
	}
);

/*$('#modal_galerie_reservation .etape1 .next_button').click(function(e) {
	e.preventDefault();
	$('#modal_galerie_reservation .content.etape1').addClass('hidden');
	$('#modal_galerie_reservation .content.etape2').removeClass('hidden');
});*/


/*$("#datepicker").datepicker({
  onSelect: function(dateText) {
    console.log("Selected date: " + dateText + "; input's current value: " + this.value);
  }
});*/


// Changement select horaires en fonction du jours
function changeHoraires(date) {  
	//console.log(date);
	var data = new Array();
    data.push({name: "date", value: date});

    $.ajax({
        url: '/admin/php/ajax_change_horaires.php',
        type: 'post',
        data: $.param(data),
        success: function(resultat) { //console.log(resultat);
            
            $('#select_horaire').html(resultat);
            $('#select_horaire').css('border', '1px solid orange');
            setTimeout(function(){
			    $('#select_horaire').css('border', '1px solid black');
			}, 500);
        }
    });
}



$('#modal_galerie_reservation .etape1 .next_button').click(function(e) {
	e.preventDefault();

	// Récuperation des valeurs de l'étape 
	var horaires_id = $('#select_horaire').val();
	var horaires_texte = $('#select_horaire option:selected').text();
	var nbadultes = $('#select_nbadultes').val();
	var nbenfants = $('#select_nbenfants').val();
	var date = $('#input_hidden_date').val();

	if($('#terrasse').prop('checked') == true) {
		var terrasse = 'Oui';
	} else {
		var terrasse = 'Non';
	}
	if($('#chaise_haute').prop('checked') == true) {
		var chaise_haute = 'Oui';
	} else {
		var chaise_haute = 'Non';
	}
	
	// Ajout des valeurs de l'étape au résumé de l'étape 
	$('.append_horaires').html(horaires_texte);
	$('.append_nbadultes').html(nbadultes);
	$('.append_nbenfants').html(nbenfants);
	$('.append_date').html(date);
	$('.append_terrasse').html(terrasse);
	$('.append_chaise_haute').html(chaise_haute);

	// Si il y a 0 personnes inscrites, mettre en rouge
	nb_personnes = nbadultes + nbenfants;
	if(nb_personnes == 0) {
		$('.append_nbadultes').addClass('red');
		$('.append_nbenfants').addClass('red');
		$('.form_reservation .etape2 .submit').prop('disabled', true);
		$('.check_full_alert.no_one').removeClass('hidden');
	}

	// Vérification de la disponibilité (restaurant et terrasse)
	$.ajax({
		type: 'GET',
		url: '/admin/php/check_if_full.php?timeslot_id='+horaires_id+'&terrasse='+terrasse+'&date='+date,
		success: function(data) {
			//alert(data);
			if(data == 'restaurant_full' || data == 'terrasse_full' || data == '3_days_reservation') {
				$('.form_reservation .infos_perso').addClass('hidden');
				$('.form_reservation .etape2 .submit').prop('disabled', true);
			}
			if(data == 'restaurant_full') {
				$('.append_horaires').addClass('red');
				$('.append_date').addClass('red');
				$('.check_full_alert.restaurant').removeClass('hidden');
			}
			if(data == 'terrasse_full') {
				$('.append_terrasse').addClass('red');
				$('.check_full_alert.terrasse').removeClass('hidden');
			}
			if(data == '3_days_reservation') {
				$('.append_date').addClass('red');
				$('.check_full_alert.same_day').removeClass('hidden');
			}
		},
		error: function() {
			if(debug_mode == true) { 
				console.log('Erreur lors de la vérification de la disponibilité'); 
			} 
		}
	});

	$('#modal_galerie_reservation .content.etape1').addClass('hidden');
	$('#modal_galerie_reservation .content.etape2').removeClass('hidden');
});

$('#modal_galerie_reservation .etape2 .bouton_retour').click(function(e) {
	e.preventDefault();
	$('#modal_galerie_reservation .content.etape2').addClass('hidden');
	$('#modal_galerie_reservation .content.etape1').removeClass('hidden');
	$('.append_nbadultes').removeClass('red');
	$('.append_nbenfants').removeClass('red');
	$('.append_horaires').removeClass('red');
	$('.append_date').removeClass('red');
	$('.append_terrasse').removeClass('red');
	$('.form_reservation .infos_perso').removeClass('hidden');
	$('.form_reservation .etape2 .submit').prop('disabled', false);
	$('.check_full_alert').addClass('hidden');
});

$('#modal_galerie_reservation .etape3 .finish').click(function(e) {
	e.preventDefault();
	window.setTimeout(function(){ 
        $('#modal_galerie_reservation .content.etape2').addClass('hidden');
		$('#modal_galerie_reservation .content.etape3').addClass('hidden');
		$('#modal_galerie_reservation .content.etape1').removeClass('hidden');
		$('.append_nbadultes').removeClass('red');
		$('.append_nbenfants').removeClass('red');
    },500);
});




$(function() {

    /*$(document).off('submit');*/
    $(document).on('submit', '#form_reservation', function(e) {

        e.preventDefault(); // Empêcher la soumission du formulaire
        e.stopImmediatePropagation();

        var $form_object = $(this); // L'objet jQuery du formulaire

        nbadultes = $('#select_nbadultes').val();
        nbenfants = $('#select_nbenfants').val();
        nb_personnes = nbenfants + nbadultes;
        if($('#terrasse').prop('checked') == true) {
			var terrasse = 'Oui';
		} else {
			var terrasse = 'Non';
		}
		if($('#chaise_haute').prop('checked') == true) {
			var chaise_haute = 'Oui';
		} else {
			var chaise_haute = 'Non';
		}
        nom = $('#nom').val();
        telephone = $('#telephone').val();
        email = $('#email').val();

        // Convertir les données du form en array
        var data = $(this).serializeArray();
        data.push({name: "terrasse", value: terrasse});
        data.push({name: "chaise_haute", value: chaise_haute});

        // Je vérifie une première fois pour ne pas lancer la requête HTTP
        // si je sais que mon PHP renverra une erreur
        if(nom === '' || email === '' || telephone === '') {
            var missing_nom = ''; if(nom == '') { missing_nom = ', nom'; }
            var missing_mail = ''; if(email == '') { missing_mail = ', mail'; }
            var missing_telephone = ''; if(telephone == '') { missing_telephone = ', telephone'; }

            var missing = missing_nom+missing_mail+missing_telephone;
            missing = missing.substring(2);
            var alert_string_start = $('.form_alert_string').html();
            var alert_string = alert_string_start+missing;

            alert(alert_string);
        } else if(nb_personnes == 0) {
        	var alert_string = "Vous ne pouvez pas réserver pour 0 personnes.";
            alert(alert_string);
        } else {
            // Envoi de la requête HTTP en mode asynchrone
            $.ajax({
                url: $form_object.attr('action'), // Le nom du fichier indiqué dans le formulaire
                type: $form_object.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
                data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
                success: function(resultat) { // Je récupère la réponse du fichier PHP
                	/*alert(resultat);*/
                	$('#modal_galerie_reservation .content.etape2').addClass('hidden');
					$('#modal_galerie_reservation .content.etape3').removeClass('hidden');
                    if(resultat == 'ok') { 
                        $('#confirmation_reservation_ok').css('display', 'block');
                    } else {
                        $('#confirmation_reservation_fail').css('display', 'block');
                    }
                }
            });
        }

        return false;
    });
});