/*
Script by : Clem
---------------------------------------
||              JS Menu              ||
---------------------------------------


*/


$(document).ready(function() {
	
	// GESTION DU CLIC SUR UN BOUTON DE LA NAV MONTHS
	$('.months_nav .nav').on('click', function(e) { // Au clic sur un élément

		var current_month = $('.month_title.active').attr('data-nbmonth');
		current_month = parseInt(current_month);
		//console.log('current_month : '+current_month);

		// Déterminer le prochain mois
		if($(this).hasClass('previous')) {
			new_month = current_month - 1;
			if(new_month == 0) { new_month = 12; }
		}
		if($(this).hasClass('next')) {
			new_month = current_month + 1;
			if(new_month == 13) { new_month = 1; }
		}
		//console.log('new_month : '+new_month);

		// Cacher tous les éléments
		$('.month_title').removeClass('active');
		$('.month_container').removeClass('active');

		// Afficher les éléments du bon mois
		$('.month_title[data-nbmonth="'+new_month+'"]').addClass('active');
		$('.month_container[data-nbmonth="'+new_month+'"]').addClass('active');

	});

});