
$(document).ready(function() {

	$(document).on('submit', '.cancel_reservation', function(e) {

        e.preventDefault(); // Empêcher la soumission du formulaire
        var $form_object = $(this); // L'objet jQuery du formulaire

        var data = $(this).serializeArray();

        // Envoi de la requête HTTP en mode asynchrone
        $.ajax({
            url: $form_object.attr('action'), // Le nom du fichier indiqué dans le formulaire
            type: $form_object.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
            data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
            success: function(resultat) { // Je récupère la réponse du fichier PHP
                /*alert(resultat);*/
                if(resultat == 'ok') {
                    $form_object.parent().parent().find('.submit_confirm_reservation').prop('disabled', true);
                    $form_object.parent().parent().find('.submit_confirm_reservation').html('Annulée');
                    $form_object.parent().parent().find('.submit_cancel_reservation').prop('disabled', true);
                    $form_object.parent().parent().find('.submit_cancel_reservation').html('Contact demandé');
                }
            }
        });
    });
	
});