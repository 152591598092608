
$(document).ready(function() {

    // Gestion bouton "Main menu"
    $('.btn_main_menu').click(function (e) { 
        $('.app_container .page').addClass('hidden');
        $('.app_container .main_menu').removeClass('hidden');
    });

    // Gestion choix menu lvl 1
    $('.lvl1_btn').click(function (e) { 
        $('.app_container .page').addClass('hidden');
        $('.app_container .lvl1_contents').removeClass('hidden');
    });
});
